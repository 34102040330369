import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "./index.css";
import "./font/fonts.css";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);

reportWebVitals();

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    console.info(
      "Received a service worker update, unregister old service worker and restart browser"
    );
    registration.unregister().then(() => {
      window.location.reload();
    });
  },
  onSuccess: (_) => {
    console.log("New service worker loaded");
  },
});
