function getWebPlayerParameters() {
    const params = new URLSearchParams(document.location.search)
    return {
        playerId: params.get("playerId"),
        containerId: params.get("iframeId") || params.get("containerId"),
        durationInMilliseconds: parseInt(params.get("duration"), 10) || null
    }
}

export const webPlayerParameters = getWebPlayerParameters()

function sendEvent(event) {
    const containerId = webPlayerParameters.containerId
    const targetWindow = window.parent;
    targetWindow.postMessage({ event: event, frameId: containerId }, "*")
}

function stopRunning(runningOnWebplayerStateChangeHandler) {
    sendEvent("end")
    runningOnWebplayerStateChangeHandler(false)
}

function startRunning(runningOnWebplayerStateChangeHandler) {
    sendEvent("playing")
    runningOnWebplayerStateChangeHandler(true)
}

function buildOnPostMessage(runningOnWebplayerStateChangeHandler) {
    function onPostMessage(message) {
        const containerId = webPlayerParameters.containerId
        const { data } = message

        if (data.containerId !== containerId && data.frameId !== containerId) {
            return
        }

        console.debug("Received post message from webplayer", data)
        if (data.event === "start") {
            startRunning(runningOnWebplayerStateChangeHandler);
        } else if (data.event === "stop") {
            stopRunning(runningOnWebplayerStateChangeHandler)
        }
    }

    return onPostMessage
}

export function initializeWebPlayerIntegration(runningOnWebplayerStateChangeHandler) {
    const onPostMessageEventListener = buildOnPostMessage(runningOnWebplayerStateChangeHandler)
    const domContentLoadedListener = () => {
        sendEvent("loaded")
    }

    window.addEventListener("message", onPostMessageEventListener)
    if (document.readyState === "complete") {
        sendEvent("loaded")
    } else {
        document.addEventListener("DOMContentLoaded", domContentLoadedListener)
    }

    return () => {
        window.removeEventListener("message", onPostMessageEventListener)
        document.removeEventListener("DOMContentLoaded", domContentLoadedListener)
    }
}

export function initializeWebPlayerPumpStateIntegration(pumpStateChangeHandler) {
    const onPostMessage = postMessage => {
        const { data: {event, message} } = postMessage;
        if (event === "PUMP_STATE") {
           pumpStateChangeHandler && pumpStateChangeHandler(message.state);
        }
    };

    subscribeFor("PUMP_STATE");
    window.addEventListener("message", onPostMessage);

    return () => {
        window.removeEventListener("message", onPostMessage);
    }
}

export function initializeWebPlayerDurationTimeout(runningOnWebplayerStateChangeHandler) {
    const durationStr = webPlayerParameters.durationInMilliseconds
    if (durationStr) {
        const duration = parseInt(durationStr, 10)
        return setTimeout(() => {
            stopRunning(runningOnWebplayerStateChangeHandler)
        }, duration)
    }
    return undefined
}

export function subscribeFor(eventMessage) {
    window.parent.postMessage(
        {
            event: "subscribe",
            eventMessage: eventMessage,
            frameId: webPlayerParameters.containerId
        },
        "*"
    );
}

export function subscribeForComponentData(componentId) {
    window.parent.postMessage(
        { event: "componentData", frameId: webPlayerParameters.containerId, componentId: componentId },
        "*"
    );
}
