import {createContext, useEffect, useState} from "react";
import {setLocale, setTranslations} from "react-i18nify";
import translations from "../i18n/translations";
import {getPrefixedKey} from "../utils/utils";

const LanguageContext = createContext(undefined);
const LanguageDispatchContext = createContext(undefined);
const languageLocalStorageKey = getPrefixedKey("activeLanguage")

const defaultLanguage = "en"

const languageArray = [
        "de",
        "en",
        "es",
        "fr",
        "hu",
        "it",
        "nl",
        "bg",
        "sl",
        "ro",
        "cz",
        "sk",
        "pl",
]

function LanguageProvider({ children }) {
    const [activeLanguage, setActiveLanguage] = useState(defaultLanguage);

    useEffect(() => {
        setTranslations(translations);
        const handleStorageChange = (event) => {
            const { storageArea } = event;
            if (storageArea[languageLocalStorageKey]) {
                setLocale(storageArea[languageLocalStorageKey]);
                setActiveLanguage(storageArea[languageLocalStorageKey]);
            }
        };
        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        }
    }, []);

    return (
        <LanguageContext.Provider value={{activeLanguage, languageArray}}>
            <LanguageDispatchContext.Provider value={setActiveLanguage}>
                {children}
            </LanguageDispatchContext.Provider>
        </LanguageContext.Provider>
    )
}

function selectLanguage(language) {
    localStorage.setItem(languageLocalStorageKey, language);
}

export { LanguageProvider, LanguageContext, LanguageDispatchContext, selectLanguage }
