import Routes from "./Routes";
import "./App.css";
import {WebplayerIntegrationProvider} from "../webplayer-integration/WebplayerIntegrationProvider";
import {LanguageProvider} from "../language-provider/LanguageProvider";

function App() {
  return (
    <div className="App">
      <WebplayerIntegrationProvider>
        <LanguageProvider>
          <Routes />
        </LanguageProvider>
      </WebplayerIntegrationProvider>
    </div>
  );
}

export default App;
