import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

const Background = lazy(() => import("../containers/background/luxemburgBackground"));
const Pumpconfirmation1 = lazy(() => import("../containers/closed/pumpconfirmation1"));
const Pumpconfirmation2 = lazy(() => import("../containers/closed/pumpconfirmation2"));
const Pumpconfirmation3 = lazy(() => import("../containers/closed/pumpconfirmation3"));
const Transaction = lazy(() => import("../containers/transaction/transaction"));
const Facility = lazy(() => import("../containers/facility/facility"));
const Safety = lazy(() => import("../containers/safety/safety"));
const LanguageSelection = lazy(() => import("../containers/language/languageSelection"));
const PromoBanner = lazy(() => import("../containers/promoBanner/promoBanner"));
const Weather = lazy(() => import("../containers/weather/v1/weather"));
const Closed = lazy(() => import("../containers/closed/closed"));
const LayoutBar = lazy(() => import("../containers/layoutBar/LayoutBar"));

const Routes =  () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path="/" component={Background} />
        <Route exact path="/pumpconfirmation1" component={Pumpconfirmation1} />
        <Route exact path="/pumpconfirmation2" component={Pumpconfirmation2} />
        <Route exact path="/pumpconfirmation3" component={Pumpconfirmation3} />
        <Route exact path="/language_selection" component={LanguageSelection} /> 
        <Route exact path="/transaction" component={Transaction} />
        <Route exact path="/facility" component={Facility} />
        <Route exact path="/Safety" component={Safety} />
        <Route exact path="/promobanner" component={PromoBanner} />
        <Route exact path="/weather" component={Weather} />
        <Route exact path="/closed" component={Closed} />
        <Route exact path="/layout_bar" component={LayoutBar} />
        <Route path="*">Not Found</Route>
      </Switch>
    </Suspense>
  );
};
export default Routes;
