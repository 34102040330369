import React, {createContext, useEffect, useState} from "react";
import {
    initializeWebPlayerDurationTimeout,
    initializeWebPlayerIntegration,
    initializeWebPlayerPumpStateIntegration,
} from "./webplayerIntegration";

const WebplayerIntegrationContext = createContext(undefined);
const WebplayerIntegrationDispatchContext = createContext(undefined);

function WebplayerIntegrationProvider({ children }) {
    const [runningOnWebplayer, setRunningOnWebplayer] = useState(false)
    const [pumpState, setPumpState] = useState('');

    useEffect(() => {
        const deInitializeWebPlayerIntegration = initializeWebPlayerIntegration(setRunningOnWebplayer)
        return () => {
            deInitializeWebPlayerIntegration()
        }
    }, [setRunningOnWebplayer])

    useEffect(() => {
        const deInitializeWebPlayerPumpStateIntegration = initializeWebPlayerPumpStateIntegration(setPumpState);
        return () => {
            deInitializeWebPlayerPumpStateIntegration();
        }
    }, [setPumpState]);

    useEffect(() => {
        console.debug("Running on webplayer: ", runningOnWebplayer)
        let timeoutId = undefined;
        if (runningOnWebplayer) {
            timeoutId = initializeWebPlayerDurationTimeout(setRunningOnWebplayer)
            console.debug("Initialized timeout: ", timeoutId)
        }
        return () => {
            console.debug("Clearing timeout: ", timeoutId)
            clearTimeout(timeoutId)
        }
    }, [runningOnWebplayer, setRunningOnWebplayer])

    return (
        <WebplayerIntegrationContext.Provider value={{runningOnWebplayer, pumpState}}>
            <WebplayerIntegrationDispatchContext.Provider value={setRunningOnWebplayer}>
                {children}
            </WebplayerIntegrationDispatchContext.Provider>
        </WebplayerIntegrationContext.Provider>
    );
}

export { WebplayerIntegrationProvider, WebplayerIntegrationContext, WebplayerIntegrationDispatchContext };
